'use strict';


angular.module('kljDigitalLibraryApp')

.controller('IndexCtrl', ["$rootScope", "$scope", "$interval", "$state", "$stateParams",
    "commonVariables", "common", "appConfig", "caseNotesService", "savedSearchesService",
    "searchHistoryService", "SubscriptionService", "NotificationsService", "savedTabsService",
    "AutoSuggestService", "AccountDetailsService", "ngToast", "IndexService", "OpenedTabService",
    "$uibModal", "$localStorage", "User", "Auth",
    function($rootScope, $scope, $interval, $state, $stateParams,
        commonVariables, common, appConfig, caseNotesService, savedSearchesService,
        searchHistoryService, SubscriptionService, NotificationsService, savedTabsService,
        AutoSuggestService, AccountDetailsService, ngToast, IndexService, OpenedTabService,
        $uibModal, $localStorage, User, Auth) {
        const DELETE = 'delete';
        const JOURNALS = 'Journals';
        const STATUES = 'Statues';
        $scope.statueSuggestions = [];
        $scope.showSearchHistory = true;
        $scope.checkToggle = false;
        $scope.date = {};
        $scope.notesFinished = false;
        $scope.loadingSavedSearch = true;
        var orderMapping = {
            searchValue: "1,",
            Subject: "2,",
            Bench: "3,",
            Party: "4,",
            Judge: "5,",
            Counsel: "6,",
            Council: "6,",
            Court: "7,",
            'Writ Petition': "8,",
            Citation: '9,',
            Reference: "10,",
            Judgement: '11,',
            Date: '11,'
        }


        $scope.init = function() {


            $scope.SAVED_SEARCH_CONFIRMATION_MESSAGE = "Are you sure to remove saved search?";
            $scope.SAVED_TABS_ITEM_CONFIRMATION_MESSAGE = "Are you sure to remove saved tab?";
            $scope.SAVED_NOTES_CONFIRMATION_MESSAGE = "Are you sure to remove saved note?";
            $scope.isHome = false;
            $scope.username = $rootScope.username ? $rootScope.username : 'User';
            // $scope.username = 'User';





            // if ($state.current.name == 'index.home' ||
            //   $state.current.name == 'main.subscriptions' ||
            //   $state.current.name == 'index.subscriptionDetails' ||
            //   $state.current.name == 'index.subscriptionOrderDetails' ||
            //   $state.current.name == 'index.subscriptionHistory' ||
            //   $state.current.name == 'index.subscription-details' ||
            //   $state.current.name == 'index.subscription-plan-details' ||
            //   $state.current.name == 'index.order' ||
            //   $state.current.name == 'index.account-detail' ||
            //   $state.current.name == 'main.subscriptions') {
            //   $scope.isHome = true;
            // } else {
            //   $scope.isHome = false;
            // }
            $scope.isTrialPlan = false;
            if ($rootScope.offlineApp === true) {
                // make this value read from dongle
                // $scope.appTitle = $localStorage.title;
                $scope.waitTime= 1000;
                if(appConfig.OFFLINE_APP_TYPE=="KLJ-Combo-Desktop"){
                     $scope.categoryList = ['KLJ','KLJ Tribunal'];
                }
                if(appConfig.OFFLINE_APP_TYPE=="KLJ-Desktop"){
                     $scope.categoryList = ['KLJ'];
                }
                if(appConfig.OFFLINE_APP_TYPE=="KLJ-Tribunal-Desktop"){
                     $scope.categoryList = ['KLJ Tribunal'];
                }
                 if(!$localStorage.title){
                    $localStorage.title = $scope.categoryList[0];
                    $scope.appTitle = $localStorage.title;
                 }
                 else{
                    $scope.appTitle = $localStorage.title;
                 }
                
                    // getAppTitle();
                    // //TODO: uncomment this line
                    // runTimer();
            } else {
                $scope.waitTime= 200;
                if (IndexService.getList()) {
                    getCategoryList();
                    $scope.isTrialPlan = IndexService.isTrial($scope.appTitle);
                } else {
                    getActiveList();
                }
                $scope.appTitle = $localStorage.title ? $localStorage.title : $scope.categoryList[0];
            }
            $scope.example = ($scope.appTitle == "KLJ") ? "[2014] 1 Kar. L.J. 442" : "[2013] 77 Kar. L.J. 336 (Tri.)";

            $scope.showNotes = false;
            $scope.showSavedSearch = false;
            $scope.showSavedTabs = false;
            $scope.searchHistory = false;
            $scope.showsidemenu = false;

            $scope.caseNotesList = [];
            $scope.savedSearchesData = [];
            $scope.searchHistoryData = [];
            $scope.notifications = [];
            $scope.savedTabsData = [];
            $scope.caseNotesPNo = 0;
            $scope.savedSearchesPNo = 0;
            $scope.searchHistoryPNo = 0;
            $scope.savedTabsPNo = 0;
            $scope.filterDateOption = appConfig.FILTER_DATE_OPTIONS;
            if (!$rootScope.offlineApp) {getRenewalDays();
                userNotifications();
            }
            if (!$rootScope.offlineApp && !$rootScope.username) {
                getCurrentUser();
            }
            
            $rootScope.$on('$stateChangeSuccess', $scope.closeDiv);
        }

        $scope.resetFilters = function() {
                if ($state.current.name === 'index.case-list') {
                    $state.go("index.case-list", {
                        pageno: undefined,
                        subject_subject: undefined,
                        bench_type: undefined,
                        judge_name: undefined,
                        counsel_name: undefined,
                        court_court: undefined,
                        writ_petition_query: undefined,
                        statues_query: undefined,
                        citation_page_number: undefined,
                        citation_volume: undefined,
                        citation_year: undefined,
                        reference_query: undefined,
                        judgement_date_exact_date: undefined,
                        judgement_date_start_date: undefined,
                        judgement_date_end_date: undefined,
                        search_id: undefined,
                        party_petitioner_name: undefined,
                        party_respondent_name: undefined,
                        page_number_page_number: undefined,
                        page_number_year: undefined,
                        page_number_volume: undefined,
                        subject_section: undefined,
                        note_id: undefined,
                        saved_tab_id: undefined,
                        searchValue:undefined
                    });

                    $scope.date = {};

                } else if ($state.current.name === 'index.statues') {
                    $state.go("index.statues", {
                        statues_topic: undefined,
                        statues_full_text: undefined,
                        statues_act: undefined,
                        search_id: undefined,
                        note_id: undefined,
                        saved_tab_id: undefined,
                    });
                } else if ($state.current.name === 'index.journals') {
                    $state.go("index.journals", {
                        journals_topic: undefined,
                        journals_full_text: undefined,
                        search_id: undefined,
                        note_id: undefined,
                        saved_tab_id: undefined,
                    });
                }
            }
            //get Apptile for offline
        var getAppTitle = function() {
            IndexService.appTitle()
                .then(function(data) {      

                    // console.log( "get Apptitle=======data=====",data )      
                    $scope.categoryList = data.data; 
                    if($rootScope.offlineApp){
                         var userData = data;
                        
                        if(userData.data.write==true){
                            // alert(userData.data.message);
                        }
                        else if(userData.data.emptyDongle==true){
                            console.log("userData.data.message ", userData.data.message);
                        }
                        else{
                            // console.log("in else of getAppTitle userData>>>>>>>>> ",userData);
                            $scope.categoryList = userData.data.caseCategory;
                            $scope.appTitle =  $scope.categoryList[0]; 
                            $localStorage.title =  $scope.appTitle;
                        }
                        
                    }
                    else{
                        $scope.appTitle =  $scope.categoryList[0];                    
                        $localStorage.title =  $scope.appTitle;
                    }                   
                    
                })
                .catch(function(err) {

                        if(!$scope.popupCounter){
                            // showCounterModal("Please Insert Dongle ");
                             showCounterModal(err.message);
                            $scope.popupCounter = true;
                        }
                });
        }
        var runTimer = function() {
            $scope.Timer = $interval(function() {
                //Display the current time.
                IndexService.verifyDongle()
                    .then(function(data) {
                        // $scope.appTitle = data;
                        // console.log("data in runTimer response>>>>>>>>> ",data);
                        if(data.dongle){
                            // getAppTitle();
                            if ($scope.$modalInstance) {
                                // console.log("close my instance")
                                
                                $scope.popupCounter = false;
                                $scope.$modalInstance.close('OK');
                            }
                        }
                        
                    })
                    .catch(function(err) {

                        if(!err.dongle){
                           if(!$scope.popupCounter){
                                showCounterModal(err.message);
                                $scope.popupCounter = true;
                            }  
                        }               
                                              
                    });
            }, 5000);
        }
        var getActiveList = function() {
            return IndexService.getCurrentList()

            .then(function(response) {

                IndexService.storeList(response);
                getCategoryList();
                $scope.isTrialPlan = IndexService.isTrial($scope.appTitle);
            })

            .catch(function(error) {
                console.log("error from current list-->", error);
            });
        }

        var getCategoryList = function() {
            $scope.categoryList = IndexService.planInfoList();
            $scope.appTitle = $localStorage.title ? $localStorage.title : $scope.categoryList[0];
            $localStorage.title = $localStorage.title ? $localStorage.title : $scope.appTitle;
        }
        var getOfflineCategoryList=function(){
            // $scope.categoryList = IndexService.planInfoList();
            $scope.appTitle = $localStorage.title ? $localStorage.title : $scope.categoryList[0];
            $localStorage.title = $localStorage.title ? $localStorage.title : $scope.appTitle;
            
        }
        $scope.criteria = {
            citation: {},
            subject: {},
            bench_type: undefined
        }

        $scope.$on('home', function(data) {
            $scope.isHome = true;
            $scope.criteria = {
                citation: {},
                subject: {},
                bench_type: undefined
            }

            $scope.date = {};

        })

        $scope.getTitle = function(name) {


            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmationBox.html',
                controller: 'ConfirmationCtrl',
                size: 100,
                resolve: {
                    confirmationMessage: function() {
                        return "Are you sure to switch to " + name + "?";
                    }
                }
            });

            modalInstance.result.then(function(data) {
                if (data === 'delete') {
                    $scope.appTitle = name;
                    $localStorage.title = $scope.appTitle;
                    $scope.example = ($scope.appTitle == "KLJ") ? "[2014] 1 Kar. L.J. 442" : "[2013] 77 Kar. L.J. 336 (Tri.)";
                    // $scope.isTrialPlan = IndexService.isTrail($scope.appTitle);
                    // getRenewalDays();
                    $scope.closeDiv();
                    $state.go("index.home", {}, {reload: true});
                }
            })
        }

        

        $scope.counter = {};
        //Get Days Left for Renewal
        var getRenewalDays = function() {
            return SubscriptionService.getDaysForRenewal()
                .then(function(response) {
                    $scope.counter = response[$scope.appTitle];
                    if ($scope.counter) {
                        if ($scope.counter.daysLeft <= 7) {
                            showCounterModal($scope.counter);
                        }
                    }
                    else if(!$scope.counter){
                      
                        $scope.counter={};
                        $scope.counter.daysLeft=-1;
                        
                    }

                })
                .catch(function(error) {

                });
        }

        var showCounterModal = function(data) {

            $scope.$modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'remainderBox.html',
                controller: 'CounterCtrl',
                size: 100,
                backdrop: false,
                backdropClass: 'modalPopup',
                resolve: {
                    counter: function() {
                        return data;
                    }
                }
            });
        }


        var getYears = function() {

            var years = [];
            for (var index = 0; index < 100; index++) {

                years.push(new Date().getFullYear() - index);
            }
            return years;
        }

        $scope.years = getYears();

        $scope.toggleNotes = function(flag, type) {

            if (type == 'savedSearch') {

                $scope.showSavedSearch = flag;
                $scope.showNotes = $scope.searchHistory = $scope.showSavedTabs = $scope.showsidemenu = false;
                if (flag) {
                    $scope.savedSearchesPNo = 0;
                    getSavedSearchesList();
                }

            } else if (type == 'notes') {

                $scope.showNotes = flag;
                $scope.showSavedSearch = $scope.searchHistory = $scope.showSavedTabs = $scope.showsidemenu = false;
                if (flag) {
                    $scope.caseNotesPNo = 0;
                    infinteScrollCaseNotes();
                }

            } else if (type == 'searchHistory') {

                $scope.searchHistory = !$scope.searchHistory;
                $scope.showNotes = $scope.showSavedSearch = $scope.showSavedTabs = $scope.showsidemenu = false;
                if ($scope.searchHistory) {
                    $scope.searchHistoryPNo = 0;
                    getSearchHistory();
                }
            } else if (type == 'savedTabs') {

                $scope.showSavedTabs = flag;
                $scope.showNotes = $scope.showSavedSearch = $scope.searchHistory = $scope.showsidemenu = false;
                if (flag) {
                    $scope.savedTabsPNo = 0;
                    getSavedTabsList();
                }
            }
        }


        $scope.switchToggleNotes = function() {

            if ($scope.checkToggle == true) {
                $scope.checkToggle = !$scope.checkToggle;
                return false;
            } else if ($scope.checkToggle == false) {
                $scope.checkToggle = !$scope.checkToggle;
                return true;
            }

        }

        $scope.closeDiv = function() {

            $scope.showNotes = $scope.showSavedSearch = $scope.showSavedTabs = $scope.searchHistory = $scope.showsidemenu = false;
            $scope.filterBy = undefined;

        }

        $scope.logout = function() {

            Auth.logout();
            $state.go('login');
            $localStorage.$reset();
        }

        $scope.showSidebarmenu = function(flag, filterBy) {
            $scope.closeDiv();
            $scope.suggestions = [];
            $scope.statueSuggestions = [];
            $scope.showsidemenu = flag;
            $scope.filterBy = filterBy;
        }

        $scope.onDateFilterChange = function(dateFilter) {

            switch (dateFilter) {

                case 'Exact':

                    if ($scope.criteria && $scope.criteria) {
                        if ($scope.criteria.judgement_date) {
                         
                            $scope.criteria.judgement_date.start_date = undefined;
                            $scope.criteria.judgement_date.end_date = undefined;
                        }
                        $scope.date.start_date = undefined;
                        $scope.date.end_date = undefined;
                    }

                    break;

                case 'Range':
                    if ($scope.criteria && $scope.criteria) {
                        if ($scope.criteria.judgement_date) {
                            $scope.criteria.judgement_date.exact_date = undefined;
                        }
                        $scope.date.exact_date = undefined;
                    }

                    break;
            }
        }

        //Get cureent user's information
        var getCurrentUser = function() {
            var currentUser = User.get().$promise;
            currentUser
                .then(function(user) {
                    $scope.username = user.first_name + " " + user.last_name;
                    $scope.badge = user.badge;
                })

            .catch(function(error) {
                console.log("eroor", error);
            })
        }

        var sortByKey = function(array, key) {
            return array.sort(function(a, b) {
                var x = new Date(a[key]).getTime(); 
                var y = new Date(b[key]).getTime();
                return (y - x) ;
            });
        }


        //Get SavedSearches List
        var getSavedSearchesList = function() {
           
            $scope.noSavedSearches = false;
           
            $scope.infiniteScorllStatus = true;
            $scope.err = "";
            return savedSearchesService.getSavedSearchesData({
                    "pageNumber": $scope.savedSearchesPNo,
                    "pageCount": 10,
                    caseCategory: $scope.appTitle
                })
                .then(function(response) {
                    $scope.loadingSavedSearch = false;
                    $scope.infiniteScorllStatus = false;
                    if ($scope.savedSearchesPNo === 0) {
                        if($rootScope.offlineApp){
                            $scope.savedSearchesData = sortByKey(response,'created_at'); 
                        }else {
                            $scope.savedSearchesData = response;
                        }
                        
                    } else {
                        if($rootScope.offlineApp){
                            $scope.savedSearchesData = sortByKey($scope.savedSearchesData.concat(response),'created_at');
                        }else{
                            $scope.savedSearchesData = $scope.savedSearchesData.concat(response);
                        }
                    }
                    if ($scope.savedSearchesData.length === 0) {
                        $scope.noSavedSearches = true;
                    }
                    $scope.savedSearchesPNo = $scope.savedSearchesPNo + 1;
                })
                .catch(function(error) {
                    console.log("eroor-->", error);
                    $scope.noSavedSearches = true;
                    $scope.savedSearchesData = [];
                    $scope.loadingSavedSearch = false;
                    $scope.infiniteScorllStatus = false;
                    $scope.savedSearchesPNo = 0;
                    $scope.err = error.data;
                    // console.log("error from service-->", error);

                });
        }

        $scope.getSavedSearchesList = function(event) {
            console.log("event-->", event);
        }

        //Get SavedTabs List
        var getSavedTabsList = function() {
            return savedTabsService.getData({
                    "pageNumber": $scope.savedTabsPNo,
                    caseCategory: $scope.appTitle,
                    "pageCount": 1000
                })
                .then(function(response) {
                    if ($scope.savedTabsPNo === 0) {
                        if($rootScope.offlineApp){
                            $scope.savedTabsData = sortByKey(response,'created_at');
                        }else {
                            $scope.savedTabsData = response;
                        }
                        
                    } else {
                        if($rootScope.offlineApp){
                            $scope.savedTabsData = sortByKey($scope.savedTabsData.concat(response), 'created_at');
                        } else{
                            $scope.savedTabsData = $scope.savedTabsData.concat(response);
                        } 
                    }
                    $scope.savedTabsPNo = $scope.savedTabsPNo + 1;
                })
                .catch(function(error) {
                    console.log("error from service inside getSavedTabsList() -->", error);
                });
        }

        //Delete saved searches with confirmation
        $scope.deleteSavedSearch = function(id) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmationBox.html',
                controller: 'ConfirmationCtrl',
                size: 100,
                resolve: {
                    confirmationMessage: function() {
                        return $scope.SAVED_SEARCH_CONFIRMATION_MESSAGE;
                    }
                }
            });

            modalInstance.result.then(function(data) {
                if (data == DELETE) {
                    var tempSavedSearchesData = {};
                    Object.assign(tempSavedSearchesData, $scope.savedSearchesData);
                    for (var i = 0; i < $scope.savedSearchesData.length; i++) {
                        if ($scope.savedSearchesData[i]._id == id) {
                            $scope.savedSearchesData.splice(i, 1);
                        }
                    }
                    if ($scope.savedSearchesData.length === 0) {
                        $scope.noSavedSearches = true;
                        $scope.err = "You do not have any saved searches.";
                    }

                    savedSearchesService.removeSavedSearch(id)

                    .then(function(response) {
                        ngToast.create('Successfully deleted Saved Search');
                        //if ($scope.savedSearchesData.length < 4) {
                            $scope.savedSearchesPNo = 1;
                            //getSavedSearchesList();
                        //}
                    })

                    .catch(function(error) {
                        Object.assign($scope.savedSearchesData, tempSavedSearchesData);
                        ngToast.warning('Internet Server Error');
                    });
                }

            }, function(error) {
                console.log("error in modalInstance-->", error);
            })

        }

        //Delete saved tabs info with confirmation
        $scope.deleteSavedTabsItem = function(id) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmationBox.html',
                controller: 'ConfirmationCtrl',
                size: 100,
                resolve: {
                    confirmationMessage: function() {
                        return $scope.SAVED_TABS_ITEM_CONFIRMATION_MESSAGE;
                    }
                }
            });

            modalInstance.result.then(function(data) {
                if (data == DELETE) {
                    var tempSavedNotes = {};
                    Object.assign(tempSavedNotes, $scope.savedTabsData);
                    for (var i = 0; i < $scope.savedTabsData.length; i++) {
                        if ($scope.savedTabsData[i]._id == id) {
                            $scope.savedTabsData.splice(i, 1);
                        }
                    }

                    savedTabsService.removeItem(id)

                    .then(function(response) {
                        ngToast.create('Deleted Saved Tab Successfully.');
                        tempSavedNotes = undefined;
                    })

                    .catch(function(error) {
                        Object.assign($scope.savedTabsData, tempSavedNotes);
                        ngToast.warning("Internet Server Error.");
                    });
                }

            }, function(error) {
                console.log("error in modalInstance-->", error);
            })

        }

        //Get SavedSearches List
        var getSearchHistory = function() {
            return searchHistoryService.getSearchHistoryInfo({
                    "pageNumber": $scope.searchHistoryPNo,
                    "pageCount": 10,
                    caseCategory: $scope.appTitle
                })
                .then(function(response) {
                    console.log("saved Search result=====>>>>", response);
                    if ($scope.searchHistoryPNo === 0) {
                        $scope.searchHistoryData = response;
                    } else {
                        $scope.searchHistoryData = $scope.searchHistoryData.concat(response);
                    }
                    $scope.searchHistoryPNo = $scope.searchHistoryPNo + 1;
                    console.log("saved Search result=====>>>>",  $scope.searchHistoryData);

                })
                .catch(function(error) {
                    console.log("error from service-->", error);
                });
        }
        
        var sortSearchArray = function(array){
            const result = array.sort(function(a,b){
                return new Date(b.created_at) - new Date(a.created_at);
              });
            return result;  
        }
        var formatPartiesTooltip = function(array) {
            var list = [];

            for (var i = 0; i < array.length; i++) {
                list.push(array[i]);
                if (array[i].type === 'CASE') {
                    if(array[i].parties && array[i].parties[0] && array[i].parties[0].petitioner){
                         list[i].partyTooltip = array[i].parties[0].petitioner + " vs " + array[i].parties[0].respondent;
                    }
                   
                } else {

                    list[i].partyTooltip = array[i].topic1;
                }

            }
            if($rootScope.offlineApp){
               list = sortByKey(list,'created_at');
            }
            return list;
        }

        //Get CaseNotes List

        var infinteScrollCaseNotes = function() {
            
            if(!$scope.notesFinished){
                caseNotesService.getCaseNotesList({
                    "pageNumber": $scope.caseNotesPNo,
                    "pageCount": 10,
                    caseCategory: $scope.appTitle
                })
                .then(function(response) {

                    $scope.notesFinished = response.finish;
                    if ($scope.caseNotesPNo === 0) {

                        if($rootScope.offlineApp){
                             $scope.caseNotesList = formatPartiesTooltip(response);
                        }
                       else{
                         $scope.caseNotesList = formatPartiesTooltip(response);
                       }

                    } else {
                        $scope.caseNotesList = formatPartiesTooltip($scope.caseNotesList.concat(response));
                    }
                    $scope.caseNotesPNo = $scope.caseNotesPNo + 1;
                })
                .catch(function(error) {
                    console.log("error from service--> note", error);
                });
            }
            
            
        }

        //Delete CaseNotes
        $scope.deleteCaseNotes = function(id) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmationBox.html',
                controller: 'ConfirmationCtrl',
                size: 100,
                resolve: {
                    confirmationMessage: function() {
                        return $scope.SAVED_NOTES_CONFIRMATION_MESSAGE;
                    }
                }
            });

            modalInstance.result.then(function(data) {
                if (data == DELETE) {

                    var tempcaseNotesList = {};
                    Object.assign(tempcaseNotesList, $scope.caseNotesList);
                    for (var i = 0; i < $scope.caseNotesList.length; i++) {
                        if ($scope.caseNotesList[i]._id == id) {
                            $scope.caseNotesList.splice(i, 1);
                        }
                    }

                    caseNotesService.removeCaseNotes(id)
                        .then(function(response) {
                            ngToast.create('Deleted Note Successfully ');
                        })
                        .catch(function(error) {
                            Object.assign($scope.caseNotesList, tempcaseNotesList);
                            ngToast.warning("Internet Server Error.");
                        });
                }

            }, function(error) {
                console.log("error in modalInstance-->", error);
            })
        }

        //Get Notifications
        var userNotifications = function() {
            NotificationsService.getList({
                    "pageNumber": 0,
                    "pageCount": 5
                })
                .then(function(response) {
                    $scope.notificationList = $scope.notifications.concat(response);
                })
                .catch(function(error) {
                    console.log("error from service-->", error);
                });
        }


        $scope.updateBadge = function() {
            
            if ($scope.badge > 0) {
                AccountDetailsService.update({
                        badge: 0
                    })
                    .then(function(data) {
                        console.log('dsfsdfsfsdffsd', data);
                        getCurrentUser();
                    })
                    .catch(function(error) {

                    });
            }
        }

        $scope.fillHistorydetails = function(info, type) {
            $scope.closeDiv();
            if (info !== 'EMPTY_STRING') {
                $scope.showSavedSearch = $scope.searchHistory = $scope.showSavedTabs = $scope.showsidemenu = $scope.showNotes = false;
                var paramObjects = {};
                if (type == "CASE") {
                    paramObjects.searchValue = info;
                    paramObjects.pageno = 1
                    $state.go("index.case-list", paramObjects);
                } else if (type == "JOURNAL") {
                    paramObjects.journals_full_text = info;
                    $state.go("index.journals", paramObjects);
                } else {
                    paramObjects.statues_full_text = info;
                    $state.go("index.statues", paramObjects);
                }
            }
        }

        $scope.init();


        //
        var getSuggestions = function(data) {
                var tmepArray = [];
                for (var index in data) {
                    tmepArray.push(data[index].text);
                }

                return tmepArray;
            }
            //Auto suggestion function

        $scope.suggestions = [];
        $scope.getAutoSuggestions = function(body){
          // console.log("request sent=====");
          body.limit = appConfig.PAGINATION_LIMIT;
          body.offset = 0;
          body.caseCategory=$scope.appTitle;
          return AutoSuggestService.autoSuggest(body)
            .then(function(data){
              // console.log(data,"===data===");
              $scope.suggestions = getSuggestions(data);
              return $scope.suggestions;
            })
            .catch(function(err){
              console.log(err,"===err===");
            })
        }
        // $scope.getAutoSuggestions = function(body) {

        //     body.limit = appConfig.PAGINATION_LIMIT;
        //     body.offset = 0;
        //     body.caseCategory = $scope.appTitle;
        //     if (!$rootScope.offlineApp) {
        //         if(body.length()>3 && body.indexOf(' ')>0){
        //             suggestionCall(body)
        //         }
                
        //     } else {

        //         $scope.promiseArray = $scope.promiseArray || [];
        //         $scope.promiseArray = $.merge($scope.promiseArray, [body]);
        //         return suggestionCallSync(body)
        //             .then(function(suggestions) {
        //                 return suggestions;
        //             });
        //     }

        // }

        // function suggestionCallSync(body) {
        //     if ($scope.promiseArray.indexOf(body) != 0) {
        //         return Promise.resolve([]);
        //     }
        //     return suggestionCall($scope.promiseArray[0])
        //         .then(function(value) {
        //             var temp = JSON.stringify($scope.promiseArray);
        //             temp = JSON.parse(temp)
        //             temp.splice(0, 1);
        //             $scope.promiseArray = temp;
        //             if ($scope.promiseArray.length > 0) {
        //                 return suggestionCallSync($scope.promiseArray[0]);
        //             } else {
        //                 return value;
        //             }
        //         })
        // }

        // function suggestionCall(body) {
        //     return AutoSuggestService.autoSuggest(body)
        //         .then(function(data) {
        //             console.log(data, "===data===");
        //             $scope.suggestions = getSuggestions(data);
        //             return $scope.suggestions;

        //         })
        //         .catch(function(err) {
        //             console.log(err, "===err===");
        //         })
        // }

        $scope.getAutoStatueSuggestions = function(body) {

            body.limit = appConfig.PAGINATION_LIMIT;
            body.offset = 0;
            body.caseCategory = $scope.appTitle;
            body.type = "Tri"
            return AutoSuggestService.statueAutoSuggest(body)
                .then(function(data) {
                    console.log("auto suggest->", data);
                    $scope.statueSuggestions = getSuggestions(data);
                    return $scope.statueSuggestions;
                })

            .catch(function(error) {
                console.log("auto error-->", error);
                console.log(error, "========statues")
            })
        }


        $scope.journalAutoSuggest = function(body) {

                body.limit = appConfig.PAGINATION_LIMIT;
                body.offset = 0;
                body.caseCategory = $scope.appTitle;
                body.type = "JOUR";
                return AutoSuggestService.journalsAutoSuggest(body)
                    .then(function(data) {
                        console.log(data, "========journals")
                        $scope.journalAutoSuggestions = getSuggestions(data);
                        return $scope.journalAutoSuggestions;
                    })
                    .catch(function(error) {

                        console.log(error, "========journals")
                    })
            }
            // $scope.searchTitles = function(){
            //   alert();
            // }

        $scope.applyFilter = function(filterBy) {


            var order = $state.params.orderOfFilters;

            if (order) {
                if (!order.includes(orderMapping[filterBy])) {
                    order += orderMapping[filterBy];
                }
            } else {
                order = orderMapping[filterBy];
            }

            if (JOURNALS === filterBy) {
                if ($scope.criteria && $scope.criteria.journals) {
                    var journals_full_text = $scope.criteria.journals.full_text;
                    var journals_topic = $scope.criteria.journals.topic;
                    if ($scope.criteria.journals.full_text) {
                        saveSearch($scope.criteria.journals.full_text, "JOURNAL");
                    }
                }
                $state.go('index.journals', { journals_topic: journals_topic, journals_full_text: journals_full_text, saved_tab_id: undefined, savedTabFromOtherPage: undefined });
                return;
            }

            if (STATUES === filterBy) {
                if ($scope.criteria && $scope.criteria.statues) {
                    var statues_full_text = $scope.criteria.statues.full_text;
                    var statues_act = $scope.criteria.statues.act;
                    var statues_topic = $scope.criteria.statues.topic;
                    if (statues_full_text) {
                        saveSearch(statues_full_text, "STATUE");
                    }
                }
                $state.go("index.statues", { statues_full_text: statues_full_text, statues_act: statues_act, statues_topic: statues_topic, saved_tab_id: undefined, savedTabFromOtherPage: undefined });
                return;
            }


            // console.log(" $scope.criteria", $scope.criteria);
            var paramsObject = getParamsObject($scope.criteria);
            if ($state.params.note_id) {
                paramsObject.note_id = $state.params.note_id;
                paramsObject.otherPage = true;
            }
            if ($state.params.saved_tab_id) {
                paramsObject.saved_tab_id = $state.params.saved_tab_id;
                paramsObject.saveTabsFromOtherPage = true;
            }
            paramsObject.search_id = undefined;


            paramsObject.orderOfFilters = order;
            // console.log("paramsobject >>>>3 ", paramsObject);
            $state.go('index.case-list', paramsObject);

        }


        $scope.getAutoStatueSuggestions = function(body) {

            body.limit = appConfig.PAGINATION_LIMIT;
            body.offset = 0;
            body.caseCategory = $scope.appTitle;
            body.type = "Tri"
            return AutoSuggestService.statueAutoSuggest(body)
                .then(function(data) {
                    console.log("auto suggest->", data);
                    $scope.statueSuggestions = getSuggestions(data);
                    return $scope.statueSuggestions;
                })

            .catch(function(error) {
                console.log("auto error-->", error);
                console.log(error, "========statues")
            })
        }


        $scope.journalAutoSuggest = function(body) {

                body.limit = appConfig.PAGINATION_LIMIT;
                body.offset = 0;
                body.caseCategory = $scope.appTitle;
                body.type = "JOUR";
                return AutoSuggestService.journalsAutoSuggest(body)
                    .then(function(data) {
                        // console.log(data, "========journals")
                        $scope.journalAutoSuggestions = getSuggestions(data);
                        return $scope.journalAutoSuggestions;
                    })
                    .catch(function(error) {

                        console.log(error, "========journals")
                    })
            }
            // $scope.searchTitles = function(){
            //   alert();
            // }

        // $scope.applyFilter = function(filterBy) {


        //     var order = $state.params.orderOfFilters;

        //     if (order) {
        //         if (!order.includes(orderMapping[filterBy])) {
        //             order += orderMapping[filterBy];
        //         }
        //     } else {
        //         order = orderMapping[filterBy];
        //     }

        //     if (JOURNALS === filterBy) {
        //         if ($scope.criteria && $scope.criteria.journals) {
        //             var journals_full_text = $scope.criteria.journals.full_text;
        //             var journals_topic = $scope.criteria.journals.topic;
        //             if ($scope.criteria.journals.full_text) {
        //                 saveSearch($scope.criteria.journals.full_text, "JOURNAL");
        //             }
        //         }
        //         $state.go('index.journals', { journals_topic: journals_topic, journals_full_text: journals_full_text, saved_tab_id: undefined, savedTabFromOtherPage: undefined });
        //         return;
        //     }

        //     if (STATUES === filterBy) {
        //         if ($scope.criteria && $scope.criteria.statues) {
        //             var statues_full_text = $scope.criteria.statues.full_text;
        //             var statues_act = $scope.criteria.statues.act;
        //             var statues_topic = $scope.criteria.statues.topic;
        //             if (statues_full_text) {
        //                 saveSearch(statues_full_text, "STATUE");
        //             }
        //         }
        //         $state.go("index.statues", { statues_full_text: statues_full_text, statues_act: statues_act, statues_topic: statues_topic, saved_tab_id: undefined, savedTabFromOtherPage: undefined });
        //         return;
        //     }


        //     // console.log(" $scope.criteria", $scope.criteria);
        //     var paramsObject = getParamsObject($scope.criteria);
        //     if ($state.params.note_id) {
        //         paramsObject.note_id = $state.params.note_id;
        //         paramsObject.otherPage = true;
        //     }
        //     if ($state.params.saved_tab_id) {
        //         paramsObject.saved_tab_id = $state.params.saved_tab_id;
        //         paramsObject.saveTabsFromOtherPage = true;
        //     }
        //     paramsObject.search_id = undefined;


        //     paramsObject.orderOfFilters = order;
        //     $state.go('index.case-list', paramsObject);

        // }


        /**
          Creates object from parameters received
        */
        var getParamsObject = function(dataReceived) {

            var paramsObject = {};
            for (var key in dataReceived) {
                if (typeof(dataReceived[key]) == 'object') {

                    for (var subkey in dataReceived[key]) {

                        if (dataReceived[key][subkey] instanceof Date) {
                            paramsObject[key + "_" + subkey] = dataReceived[key][subkey].getFullYear() + '-' + (dataReceived[key][subkey].getMonth() + 1) + '-' + dataReceived[key][subkey].getDate();
                            continue;
                        }

                        if ('citation' === key) {

                            paramsObject[key + "_" + subkey] = parseInt(dataReceived[key][subkey]);

                        } else if ('party' === key) {

                            paramsObject[key + "_" + subkey + "_name"] = dataReceived[key][subkey]['name'];
                        } else {
                            paramsObject[key + "_" + subkey] = dataReceived[key][subkey];
                        }


                    }
                } else {
                    paramsObject[key] = dataReceived[key];
                }


            }
         
            //Set start date of the Judgement Date
            if ($scope.date && $scope.date.start_date) {
                paramsObject["judgement_date_start_date"] = $scope.date.start_date.getFullYear() + '-' + ($scope.date.start_date.getMonth() + 1) + '-' + $scope.date.start_date.getDate();
               
            } else {
                paramsObject["judgement_date_start_date"] = undefined;
                //$scope.date.dateFilter = ;
            }

            //Set end date of the Judgement Date
            if ($scope.date && $scope.date.end_date) {

                paramsObject["judgement_date_end_date"] = $scope.date.end_date.getFullYear() + '-' + ($scope.date.end_date.getMonth() + 1) + '-' + $scope.date.end_date.getDate();
                
            } else {
                paramsObject["judgement_date_end_date"] = undefined;
                //$scope.date = {};
            }

            //Set Exact date of the Judgement Date
            if ($scope.date && $scope.date.exact_date) {
                paramsObject["judgement_date_exact_date"] = $scope.date.exact_date.getFullYear() + '-' + ($scope.date.exact_date.getMonth() + 1) + '-' + $scope.date.exact_date.getDate();
            } else {
                paramsObject["judgement_date_exact_date"] = undefined;
                //$scope.date = {};
            }

            return paramsObject;
        }



        /*
         *Event Listeners: communication to child controllers
         */


        //Close all popup
        $scope.$on('closeAllPopUp', function() {
            $scope.closeDiv();
        })

        //on remove filter update te sidebar
        $scope.$on('updateFilter', function(event, filter) {
            $scope.criteria[filter] = undefined;
            if (!$scope.criteria.judgement_date) {
                $scope.date = {};
            }

        })

        //Initialise all filters at sidebar after reloading
        $scope.$on('initFilterState', function(event, initialState) {
            if (initialState.criteria && initialState.criteria.judgement_date && initialState.criteria.judgement_date.exact_date) {
                $scope.date = {
                    dateFilter: 'Exact'
                }

                // initialState.criteria.judgement_date.exact_date = new Date(initialState.criteria.judgement_date.exact_date);
                $scope.date.exact_date = new Date(initialState.criteria.judgement_date.exact_date);
            }

            if (initialState.criteria && initialState.criteria.judgement_date && initialState.criteria.judgement_date.start_date) {

                $scope.date = {
                    dateFilter: 'Range'
                }

                // initialState.criteria.judgement_date.start_date = new Date(initialState.criteria.judgement_date.start_date);
                // initialState.criteria.judgement_date.end_date = new Date(initialState.criteria.judgement_date.end_date);

                $scope.date.start_date = new Date(initialState.criteria.judgement_date.start_date);
                $scope.date.end_date = new Date(initialState.criteria.judgement_date.end_date);
            }


            $scope.criteria = initialState.criteria;
        })

        //Update main search box
        $scope.$on('updateHeader', function(event, data) {

            $scope.isHome = false;
            $scope.case = {
                search: data
            }
            if (data === 'EMPTY_STRING') {
                $scope.case = {
                    search: ''
                }
            }
        })

        $scope.$on('showSearchBox', function(event, flag) {
            $scope.isHome = flag;
        })

        //Update categoryList
        $scope.$on('updateCategoryList', function(event, status) {
            if (status === true && !$rootScope.offlineApp) {
                getActiveList();
            }
        })

        //Update Journals state
        $scope.$on("initJournalFilterState", function(event, data) {

            $scope.criteria = data.criteria;
        })

        //Update Statues state
        $scope.$on("initStatuesFilterState", function(event, data) {

            $scope.criteria = data.criteria;
        })

        //Update Username
        $scope.$on("updateUsername", function(event, name) {
                $scope.username = name;
            })
            // 


        var saveSearch = function(search, type) {
            var body = {
                query: search,
                type: type,
                category: $scope.appTitle
            };
            IndexService.saveHistory(body)
                .then(function(data) {
                    // $scope.news = news;
                    // $scope.totalNews = appConfig.PAGINATION_LIMIT;
                    // PaginationService.setCount($scope.totalNews);
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        //Event Emiter: communication between controllers
        $scope.getCases = function(event, cases) {
            if (event.which === 13) {
                switch ($state.current.name) {
                    case 'index.case-list':
                        saveSearch(cases, "CASE");
                        $scope.$broadcast('updateCases', cases);
                        break;

                    case 'index.journals':
                        saveSearch(cases, "JOURNAL");
                        $scope.$broadcast('updateJournals', cases);
                        break;

                    case 'index.statues':
                        saveSearch(cases, "STATUE");
                        $scope.$broadcast('updateStatues', cases);
                        break;
                }

            }
        }


        var constructQueryParams = function(filters, type) {
            var obj = {};
            for (var i = 0; filters && i < filters.length; i++) {
                if (type === 'CASE') {
                    if (filters[i].name == 'subject') {
                        obj.subject = filters[i].value;
                    } else if (filters[i].name == 'section') {
                        obj.section = filters[i].value;
                    } else if (filters[i].name == 'bench_type') {
                        obj.bench = filters[i].value;
                    } else if (filters[i].name == 'judge') {
                        obj.judge = filters[i].value;
                    } else if (filters[i].name == 'counsel') {
                        obj.counsel = filters[i].value;
                    } else if (filters[i].name == 'petitioner') {
                        obj.petitioner = filters[i].value;
                    } else if (filters[i].name == 'respondent') {
                        obj.respondent = filters[i].value;
                    } else if (filters[i].name == 'court') {
                        obj.court = filters[i].value;
                    } else if (filters[i].name == 'writ_petition') {
                        obj.writ = filters[i].value;
                    } else if (filters[i].name == 'statues') {
                        obj.statues = filters[i].value;
                    } else if (filters[i].name == 'volume') {
                        obj.volume = filters[i].value;
                    } else if (filters[i].name == 'year') {
                        obj.year = filters[i].value;
                    } else if (filters[i].name == 'page_number') {
                        obj.page_number = filters[i].value;
                    } else if (filters[i].name == 'exact_date') {
                        obj.judgement_date_exact = filters[i].value;
                    } else if (filters[i].name == 'start_date') {
                        obj.judgement_date_start = filters[i].value;
                    } else if (filters[i].name == 'end_date') {
                        obj.judgement_date_end = filters[i].value;
                    } else {
                        obj.query = filters[i].value;
                    }
                } else if (type === 'STATUE') {
                    if (filters[i].name == 'act') {
                        obj.act = filters[i].value;
                    } else if (filters[i].name == 'full_text') {
                        obj.full_text = filters[i].value;
                    } else if (filters[i].name == 'topic') {
                        obj.topic = filters[i].value;
                    }
                } else if (type === 'JOURNAL') {
                    if (filters[i].name == 'full_text') {
                        obj.full_text = filters[i].value;
                    } else if (filters[i].name == 'topic') {
                        obj.topic = filters[i].value;
                    }
                }
            }
            return obj;
        }

        //get order of the filter
        // var getOrderOfFilters = function(queryParam, searchValue){

        //   var order = '';

        //   if(searchValue){
        //     order += '1,';
        //   }
        //   if(queryParam.subject || queryParam.section){
        //     order += '2,';
        //   }
        //   if(queryParam.bench){
        //     order += '3,'
        //   }
        //   if(queryParam.petitioner || queryParam.respondent){
        //     order += '4,'
        //   }
        //   if(queryParam.judge){
        //     order += '5,'
        //   }
        //   if(queryParam.counsel){
        //     order += '6,'
        //   }
        //   if(queryParam.court){
        //     order += '7,'
        //   }
        //   if(queryParam.writ){
        //     order += '8,'
        //   }
        //   if(queryParam.age_number || queryParam.volume || queryParam.year){
        //     order += '9,'
        //   }
        //   if(queryParam.query){
        //     order += '10,'
        //   }
        //   if(queryParam.judgement_date_exact_date || queryParam.judgement_date_end_date || queryParam.judgement_date_start_date){
        //     order += '11,'
        //   }

        //   return order;

        // }

        //Load savedSearch Data
        $scope.loadSavedSearchResult = function(data) {
            var queryParam = constructQueryParams(data.filters, data.type);
            OpenedTabService.removeAll();  //Remove All Search Tabs
            $scope.closeDiv();
            $scope.init();
            var savedSearchTabs = null;
            if (data && data.tabs) {
                savedSearchTabs = data.tabs.join();
            }
            var saveTabsFromOtherPage, otherPage;
            if ($state.params.saveTabsFromOtherPage) saveTabsFromOtherPage = true;
            if ($state.params.otherPage) otherPage = true;
            if (data.type === 'CASE') {

                // var order = getOrderOfFilters(queryParam,data.text);
                // console.log("order-->", order);
                $state.go("index.case-list", {
                    searchValue: data.text,
                    subject_subject: queryParam.subject,
                    subject_section: queryParam.section,
                    bench_type: queryParam.bench,
                    party_petitioner_name: queryParam.petitioner,
                    party_respondent_name: queryParam.respondent,
                    judge_name: queryParam.judge,
                    counsel_name: queryParam.counsel,
                    court_court: queryParam.court,
                    writ_petition_query: queryParam.writ,
                    statues_query: queryParam.statues,
                    page_number_page_number: queryParam.page_number,
                    page_number_volume: queryParam.volume,
                    page_number_year: queryParam.year,
                    reference_query: queryParam.query,
                    judgement_date_exact_date: queryParam.judgement_date_exact,
                    judgement_date_start_date: queryParam.judgement_date_start,
                    judgement_date_end_date: queryParam.judgement_date_end,
                    search_id: savedSearchTabs,
                    note_id: $state.params.note_id,
                    saveTabsFromOtherPage: saveTabsFromOtherPage,
                    otherPage: otherPage,
                    orderOfFilters: data.orderOfFilters
                });
            } else if (data.type === 'JOURNAL') {
                $state.go("index.journals", {
                    journals_topic: queryParam.topic,
                    journals_full_text: queryParam.full_text,
                    search_id: savedSearchTabs,
                    note_id: undefined
                })
            } else if (data.type === 'STATUE') {
                $state.go("index.statues", {
                    statues_act: queryParam.act,
                    statues_topic: queryParam.topic,
                    statues_full_text: queryParam.full_text,
                    search_id: savedSearchTabs,
                    note_id: undefined
                })
            }
        }

        //Load SavedTabs Details
        $scope.loadSavedTabsResult = function(data) {
            var queryParam = constructQueryParams(data.filters, data.type);
             $scope.closeDiv();
             $scope.init();
            $state.go("index.case-list", { saved_tab_id: data._id });
        }

        //Get more search history on scroll
        $scope.getMoreRecords = function() {
            getSearchHistory();
        }

        //get more search history on scroll
        $scope.getMoreSavedSearch = function() {
            getSavedSearchesList();
            $scope.infiniteScorllStatus=false;
        }

        
        //get more saved notes on scroll
        $scope.getMoreSavedNotes = function() {
            infinteScrollCaseNotes();
        }

        //get more saved tabs on scroll
        $scope.getMoreSavedTabs = function() {
            getSavedTabsList();
        }

        //Styling from javascript
        angular.element(document).ready(function() {
            var headerWidth = $(".main_head_left").outerWidth();
            var liWidth = $('.s_hist').outerWidth();
            var actualWidth = headerWidth + liWidth;
            
            $('.search_history_block').css('width', actualWidth + "px");
        });


        $(".abs_body").scroll(function(event) {
            console.log("event-->", event);
        })


        var tabList = function(data) {
                if (data.case) {
                    return data.case
                } else if(data.cases){

                    var list = "",
                        len = data.cases.length;
                    // if($state.params.saved_tab_id){
                    //   list=$state.params.saved_tab_id+",";
                    // }
                    for (var i = 0; i < len; i++) {
                        if (i == len - 1) {
                            list += data.cases[i];
                        } else {
                            list += data.cases[i] + ",";
                        }
                    }
                    return list;
                }
                else if(data.journalsAndStatues){
                    return data.journalsAndStatues;
                }
                return list;
            }
            
            //Create note id variable in url
        function createNoteID(data) {
            var list = $state.params.note_id;
            if (list && list.includes(data.case)) {
                if (list.indexOf(',') > -1) {
                    list = list.replace(',' + data.case, '').replace(data.case+',', '');
                    list += ',' + data.case
                }
                return list;
            } else if (!list) {
                list = data.case;
            } else {
                list += "," + data.case;
            }
            return list;

        }

        //Load Case/Journal/Statue notes
        $scope.loadNotes = function(data) {
           // OpenedTabService.removeAll(); //removing all open tabs
            $scope.closeDiv();
            $scope.init();
            if (data.type === 'CASE') {
                var params = {};
                if ($state.params.saveTabsFromOtherPage) params.saveTabsFromOtherPage = true;
                if ($state.current.name !== 'index.case-list') { //condition to check fromwhere 
                    //we are going to saved note
                    params = {
                        note_id: tabList(data),
                        saved_tab_id: undefined,
                        search_id: undefined,
                        subject_subject: undefined,
                        subject_section: undefined,
                        bench_type: undefined,
                        party_petitioner_name: undefined,
                        party_respondent_name: undefined,
                        judge_name: undefined,
                        counsel_name: undefined,
                        court_court: undefined,
                        writ_petition_query: undefined,
                        statues_query: undefined,
                        citation_page_number: undefined,
                        citation_volume: undefined,
                        citation_year: undefined,
                        reference_query: undefined,
                        judgement_date_exact_date: undefined,
                        judgement_date_start_date: undefined,
                        judgement_date_end_date: undefined,
                        searchValue: undefined
                    };

                    params.otherPage = 'false';
                } else {
                    params.note_id = createNoteID(data);
                    params.otherPage = 'false';
                    params.saved_tab_id = undefined;
                    params.search_id = undefined;
                }

                $state.go('index.case-list', params);

            } else if (data.type === 'JOURNAL') {
                var params = {};

                if ($state.current.name !== 'index.journals') { //condition to check fromwhere 
                    //we are going to saved note
                    params = {
                        note_id: tabList(data),
                        saved_tab_id: undefined,
                        search_id: undefined,
                        journals_full_text: undefined,
                        journals_topic: undefined

                    };
                    params.otherPage = true;
                } else {
                    params.note_id = createNoteID(data);
                    params.otherPage = false;
                }

                $state.go('index.journals', params);

            } else if (data.type === 'STATUE') {

                var params = {};

                if ($state.current.name !== 'index.statues') { //condition to check fromwhere 
                    //we are going to saved note
                    params = {
                        note_id: tabList(data),
                        saved_tab_id: undefined,
                        search_id: undefined,
                        statues_full_text: undefined,
                        statues_act: undefined,
                        statues_topic: undefined
                    };

                    params.otherPage = true;
                } else {
                    params.note_id = createNoteID(data);
                    params.otherPage = false;
                }

                $state.go('index.statues', params);

            }
        }

        $scope.loadTabs = function(data) {
            // OpenedTabService.removeAll(); //Remove All Open Tabs
            $scope.closeDiv();
            $scope.init();
            var params = {};
            if (data.type === 'CASE') {
                
                if ($state.current.name == 'index.case-list') {
                    params = {
                        saved_tab_id: tabList(data),
                        note_id: undefined,
                        search_id: undefined,
                        otherPage: undefined

                    };
                    params.saveTabsFromOtherPage = 'false';
                } else {
                    params = {
                        saved_tab_id: tabList(data),
                        note_id: undefined,
                        search_id: undefined,
                        subject_subject: undefined,
                        subject_section: undefined,
                        bench_type: undefined,
                        party_petitioner_name: undefined,
                        party_respondent_name: undefined,
                        judge_name: undefined,
                        counsel_name: undefined,
                        court_court: undefined,
                        writ_petition_query: undefined,
                        statues_query: undefined,
                        citation_page_number: undefined,
                        citation_volume: undefined,
                        citation_year: undefined,
                        reference_query: undefined,
                        judgement_date_exact_date: undefined,
                        judgement_date_start_date: undefined,
                        judgement_date_end_date: undefined,
                        searchValue: undefined,
                        otherPage: undefined

                    };
                    params.saveTabsFromOtherPage = 'false';

                }
                
                $state.go('index.case-list', params);

            } else if (data.type === 'JOURNAL') {

                if ($state.current.name == 'index.journals' || $state.params.note_id || $state.params.search_id) {
                    params = {
                        saved_tab_id: tabList(data),
                        note_id: undefined,
                        search_id: undefined
                    }
                    if($rootScope.offlineApp){
                        params.savedTabFromOtherPage = 'true';
                    }else{
                        params.savedTabFromOtherPage = 'false';
                    }

                } else {

                    params = {
                        saved_tab_id: tabList(data),
                        note_id: undefined,
                        search_id: undefined,
                        journals_full_text: undefined,
                        journals_topic: undefined
                    }
                    params.savedTabFromOtherPage = 'true';
                }
                $state.go('index.journals', params);

            } else if (data.type === 'STATUE') {

                if ($state.current.name == 'index.statues' || $state.params.note_id || $state.params.search_id) {

                    params = {
                        saved_tab_id: tabList(data),
                        note_id: undefined,
                        search_id: undefined
                    };
                    if($rootScope.offlineApp){
                        params.savedTabFromOtherPage = 'true';
                    }else{
                        params.savedTabFromOtherPage = 'false';
                    }

                } else {

                    params = {
                        saved_tab_id: tabList(data),
                        note_id: undefined,
                        search_id: undefined,
                        statues_full_text: undefined,
                        statues_act: undefined,
                        statues_topic: undefined
                    };
                    params.savedTabFromOtherPage = 'true';
                }

                $state.go('index.statues', params);

            }
        }

        $scope.otherFun = function() {

        }

        if(!$rootScope.offlineApp){
            socket.emit('create',{room:'room1'})
             socket.on('event',function(data){
             getCurrentUser();
            userNotifications();
           

        })
        }
        
       
    }
]);